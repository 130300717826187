import React, { useState } from "react"
import {
  LegacyCard,
  Collapsible,
  FormLayout,
  Select,
} from "@shopify/polaris"
import update from "immutability-helper"
import CardDivider from "../../../components/cardDivider"

export default function OrderSummaryForm({
  ButtonWrapper,
  dropdownButtonMarkup,
  state,
  setState,
}) {
  const [open, setOpen] = useState(0)
  const handleToggle = (index) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }
  return (
    <LegacyCard title="Order summary">
      <CardDivider/>
      <LegacyCard.Section>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            handleToggle(0)
          }}
          ariaExpanded={open === 0}
          ariaControls={`primary-button-collapsible`}
        >
          {dropdownButtonMarkup(
            "Merchandise thumbnail",
            "Product images",
            open === 0
          )}
        </ButtonWrapper>
      </LegacyCard.Section>
      {open === 0 && (
        <LegacyCard.Section>
          <Collapsible
            open={open === 0}
            id={`primary-button-collapsible`}
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            <LegacyCard sectioned subdued>
              <FormLayout>
                <Select
                  label="Border"
                  options={[
                    { label: "Full", value: "FULL" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.merchandiseThumbnailBorder}
                  onChange={(value) => {setState(update(state, { merchandiseThumbnailBorder: { $set: value } }))}}
                />
                <Select
                  label="Corner radius"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Large", value: "LARGE" },
                    { label: "Small", value: "SMALL" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.merchandiseThumbnailCornerRadius}
                  onChange={(value) => {setState(update(state, { merchandiseThumbnailCornerRadius: { $set: value } }))}}
                />
              </FormLayout>
            </LegacyCard>
          </Collapsible>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  )
}
